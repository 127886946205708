<template>
  <v-dialog v-model="dialog" max-width="650px">
    <template v-slot:activator="{ on }">
      <div class="text-right">
        <a class="text--primary" @click.prevent="" v-on="on">{{ $t('¿Has olvidado la contraseña?') }}</a>
      </div>
    </template>

    <v-card>
      <v-card-title>
        <span class="headline">{{ $t('Recuperar contraseña') }}</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col>
              <p>{{ $t('Introduzca su email y le enviaremos las instrucciones para recuperar su contraseña') }}</p>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pt-0 pb-0">
              <v-text-field v-model="email" :label="$t('Email')" :rules="email_rules" required/>
            </v-col>
          </v-row>
        </v-form>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="!valid" text @click="recover_password">{{ $t('Recuperar contraseña') }}</v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'RecoverPassword',
  data () {
    return {
      dialog: false,
      valid: false,
      email: '',
      email_rules: [
        v => !!v || this.$t('El email es obligatorio'),
        v => /.+@.+/.test(v) || this.$t('El email debe ser válido')
      ]
    }
  },

  methods: {
    async recover_password () {
      if (this.$refs.form.validate()) {
        const resp = await this.$store.dispatch('auth/recover_password', this.email)
        if (resp) {
          this.$notify({
            title: this.$t('Acción realizada correctamente'),
            text: this.$t('Te hemos enviado un email para que puedas restablecer tu contraseña.')
          })
          this.dialog = false
        } else {
          this.$notify({
            title: this.$t('Error'),
            text: this.$t('Se ha producido un error al solicitar la recuperación de la contraseña.'),
            style: 'error'
          })
        }
      }
    }
  }
}
</script>
